<mat-dialog-content>
  <span tabindex="1"></span>
  <div class="modal-header">
    <h3 class="fw-bold">Default Filters</h3>
    <i class="ph ph-x" style="cursor: pointer" (click)="onClose()"></i>
  </div>
  <form>
    <div class="seconize-input-filed">
      <div class="seconize-dropdown-container gap-10">
        <div class="seconize-dropdown">
          <label>User ID</label>
          <mat-form-field
            appearance="outline"
            style="width: 100%; margin-top: 4px"
            class="auto-complete-search"
          >
            <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
            <input
              type="text"
              placeholder="Select one"
              (focus)="getUsers()"
              aria-label="User Name"
              matInput
              [formControl]="selectedUserId"
              [matAutocomplete]="autoUserName"
            />
            <mat-autocomplete
              #autoUserName="matAutocomplete"
              (optionSelected)="selectFilter('User Id', selectedUserId.value)"
              [panelWidth]="240"
              classList="auto-dropdown"
              class="auto-dropdown input-dropdown"
            >
              <mat-option
                class="mat-option-custom"
                *ngFor="let item of filteredUserNameOptions | async"
                [value]="item.userName"
              >
                <span
                  *ngIf="item.name && item.name.length > 20"
                  matTooltip="{{ item.name }}"
                  >{{ item.name | slice : 0 : 20 }}...</span
                >
                <span *ngIf="item.name && item.name.length <= 20">{{
                  item.name
                }}</span>
              </mat-option>

              <mat-option *ngIf="userList.length <= 0">
                {{ isUserIdsLoading ? "Loading..." : "No User Ids Found" }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="seconize-dropdown">
          <label for="">Email ID</label>
          <mat-form-field
            appearance="outline"
            style="width: 100%; margin-top: 4px"
            class="auto-complete-search"
          >
            <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
            <input
              type="text"
              placeholder="Select one"
              (focus)="getUsers()"
              aria-label="Email Id"
              matInput
              [formControl]="selectedEmailId"
              [matAutocomplete]="autoEmailId"
            />
            <mat-autocomplete
              #autoEmailId="matAutocomplete"
              (optionSelected)="selectFilter('Email Id', selectedEmailId.value)"
              [panelWidth]="240"
              classList="auto-dropdown"
              class="auto-dropdown input-dropdown"
            >
              <mat-option
                class="mat-option-custom"
                *ngFor="let item of filteredEmailIdOptions | async"
                [value]="item.emailId"
              >
                <span
                  *ngIf="item.emailId && item.emailId.length > 20"
                  matTooltip="{{ item.emailId }}"
                  >{{ item.emailId | slice : 0 : 20 }}...</span
                >
                <span *ngIf="item.emailId && item.emailId.length <= 20">{{
                  item.emailId
                }}</span>
              </mat-option>
              <mat-option *ngIf="userList.length <= 0">
                {{ isUserIdsLoading ? "Loading..." : "No Email Ids Found" }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>

      <div class="seconize-dropdown-container gap-10">
        <!-- STATUS-->
        <div class="seconize-dropdown">
          <label>Status</label>
          <mat-form-field
            appearance="outline"
            style="width: 100%; margin-top: 4px"
            class="input-dropdown"
          >
            <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
            <mat-select
              placeholder="Select Status"
              [formControl]="selectedStatus"
              (selectionChange)="selectFilter('Status', selectedStatus.value)"
              [panelClass]="
                isDarkMode
                  ? 'seconize-dark-input-dropdown'
                  : 'seconize-input-dropdown'
              "
            >
              <mat-option *ngFor="let item of statusList" [value]="item.value"
                >{{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="seconize-dropdown">
          <label>User Type</label>
          <mat-form-field
            appearance="outline"
            style="width: 100%; margin-top: 4px"
            class="input-dropdown"
          >
            <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
            <mat-select
              placeholder="Select User Type"
              [formControl]="selectedUserType"
              (selectionChange)="getPermission()"
              [panelClass]="
                isDarkMode
                  ? 'seconize-dark-input-dropdown'
                  : 'seconize-input-dropdown'
              "
            >
              <mat-option *ngFor="let item of userTypes" [value]="item.value"
                >{{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- <div class="seconize-dropdown">
                    <div *ngIf="vulnMgmt===true">
                        <label>Vuln Role</label>
                        <mat-form-field appearance="outline" style="width: 100%; margin-top: 4px;" class="input-dropdown">
                            <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                            <mat-select placeholder="Select Vuln Role" [formControl]="selectedVulnScope" (selectionChange)="
                selectFilter('Vuln Role', selectedVulnScope.value)" [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'">
                                <mat-option *ngFor="let item of vulnScopeList" [value]="item.value">{{ item.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div> -->
      </div>
      <div class="seconize-dropdown-container gap-10">
        <div
          class="seconize-dropdown"
          [ngClass]="!selectedUserType.value ? 'disbaled-input' : ''"
        >
          <label>User Module</label>
          <mat-form-field
            appearance="outline"
            style="width: 100%; margin-top: 4px"
            class="input-dropdown"
          >
            <mat-select
              [formControl]="selectedUserRole"
              placeholder="Select Module"
              [panelClass]="
                isDarkMode
                  ? 'seconize-dark-input-dropdown'
                  : 'seconize-input-dropdown'
              "
            >
              <mat-optgroup
                *ngFor="let module of userModules"
                [label]="module.module"
              >
                <mat-option
                  *ngFor="let role of module.roles"
                  (click)="getSelectedValue(module)"
                  [value]="role.value"
                  >{{ role.name }}</mat-option
                >
              </mat-optgroup>
            </mat-select>
          </mat-form-field>
        </div>
        <div
          class="seconize-dropdown"
          [ngClass]="!selectedUserRole.value ? 'disbaled-input' : ''"
        >
          <label for="">User Permission</label>
          <mat-form-field
            appearance="outline"
            style="width: 100%; margin-top: 4px"
            class="input-dropdown"
          >
            <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
            <mat-select
              placeholder="Select Permission"
              [formControl]="selectedPermission"
              [panelClass]="
                isDarkMode
                  ? 'seconize-dark-input-dropdown'
                  : 'seconize-input-dropdown'
              "
            >
              <mat-option
                *ngFor="let item of selectedUserModule.permissions"
                [value]="item.value"
                >{{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- <div class="seconize-dropdown-container gap-10">
                <div class="seconize-dropdown" *ngIf="complianceMgmt===true">
                    <label>Audit Role</label>
                    <mat-form-field appearance="outline" style="width: 100%; margin-top: 4px;" class="input-dropdown">
                        <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                        <mat-select placeholder="Select Audit Role" [formControl]="selectedAuditScope" (selectionChange)="
               selectFilter('Audit Role', selectedAuditScope.value)" [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'">
                            <mat-option *ngFor="let item of auditScopeList" [value]="item.value">{{ item.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="seconize-dropdown" *ngIf="complianceMgmt===true">
                    <label>Policy Role</label>
                    <mat-form-field appearance="outline" style="width: 100%; margin-top: 4px;" class="input-dropdown">
                        <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                        <mat-select placeholder="Select Policy Role" [formControl]="selectedPolicyScope" (selectionChange)="
                             selectFilter('Policy Role', selectedPolicyScope.value)"
                             [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'">
                            <mat-option *ngFor="let item of policyScopeList" [value]="item.value">{{ item.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="seconize-dropdown-container gap-10">
                <div class="seconize-dropdown">
                    <label for="" *ngIf="complianceMgmt===true">Risk Role</label>
                    <mat-form-field appearance="outline" style="width: 100%; margin-top: 4px;"
                        *ngIf="complianceMgmt===true" class="input-dropdown">
                        <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                        <mat-select placeholder="Select Risk Role" [formControl]="selectedRiskScope" (selectionChange)="
                            selectFilter('Risk Role', selectedRiskScope.value)" [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'">
                            <mat-option *ngFor="let item of riskScopeList" [value]="item.value">{{ item.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="seconize-dropdown">
                    <label *ngIf="vulnMgmt===true || complianceMgmt===true ">Role</label>
                    <mat-form-field appearance="outline" style="width: 100%; margin-top: 4px;"
                        *ngIf="vulnMgmt===true || complianceMgmt===true " class="input-dropdown">
                        <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                        <mat-select placeholder="Select Role" [formControl]="selectedScope" (selectionChange)="
                          selectFilter('Scope', selectedScope.value)" [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'">
                            <mat-option *ngFor="let item of scopeList" [value]="item.value">{{ item.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div> -->

      <div class="seconize-dropdown-container gap-10">
        <!-- MFA -->
        <div class="seconize-dropdown">
          <label for="">MFA</label>
          <mat-form-field
            appearance="outline"
            style="width: 100%; margin-top: 4px"
            class="input-dropdown"
          >
            <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
            <mat-select
              placeholder="Select MFA"
              [formControl]="selectedMFA"
              (selectionChange)="selectFilter('MFA', selectedMFA.value)"
              [panelClass]="
                isDarkMode
                  ? 'seconize-dark-input-dropdown'
                  : 'seconize-input-dropdown'
              "
            >
              <mat-option *ngFor="let item of mfaList" [value]="item.value"
                >{{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- SSO -->

        <div class="seconize-dropdown">
          <label for="">SSO</label>
          <mat-form-field
            appearance="outline"
            style="width: 100%; margin-top: 4px"
            class="input-dropdown"
          >
            <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
            <mat-select
              placeholder="Select SSO"
              [formControl]="selectedSSO"
              (selectionChange)="selectFilter('SSO', selectedSSO.value)"
              [panelClass]="
                isDarkMode
                  ? 'seconize-dark-input-dropdown'
                  : 'seconize-input-dropdown'
              "
            >
              <mat-option *ngFor="let item of ssoList" [value]="item.value"
                >{{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    <div class="seconize-dropdown-container gap-10">
      <div class="seconize-dropdown">
        <label for="">Team</label>
        <mat-form-field
          appearance="outline"
          style="width: 100%; margin-top: 4px"
          class="input-dropdown"
          (click)="getAllTeams()"
        >
          <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
          <mat-select
            placeholder="Select Team"
            [formControl]="selectedTeam"
            (selectionChange)="selectFilter('Team', selectedTeam.value)"
            [panelClass]="
              isDarkMode
                ? 'seconize-dark-input-dropdown'
                : 'seconize-input-dropdown'
            "
          >
            <mat-option *ngFor="let team of teamList" [value]="team"
              >{{ team }}
            </mat-option>
            <mat-option *ngIf="teamList.length <= 0">
              {{ isTeamLoading ? "Loading..." : "No Team Found" }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="seconize-dropdown" *ngIf="vendorRiskMgmt">
        <label for="">Is Vendor User?</label>
        <mat-form-field
          appearance="outline"
          style="width: 100%; margin-top: 4px"
          class="input-dropdown"
        >
          <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
          <mat-select
            placeholder="Select Vendor User"
            [formControl]="selectedVendor"
            (selectionChange)="selectFilter('Vendor User', selectedVendor.value)"
            [panelClass]="
              isDarkMode
                ? 'seconize-dark-input-dropdown'
                : 'seconize-input-dropdown'
            "
          >
            <mat-option *ngFor="let item of booleanList" [value]="item.value">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="seconize-dropdown" *ngIf="isAdminBU && !vendorRiskMgmt">
        <label for="">Business Unit</label>
        <mat-form-field
          appearance="outline"
          style="width: 100%; margin-top: 4px"
          class="input-dropdown"
          (click)="getAllBUs()"
        >
          <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
          <mat-select
            placeholder="Select BU"
            [formControl]="selectedBU"
            (selectionChange)="selectFilter('Business Unit', selectedBU.value)"
            [panelClass]="
              isDarkMode
                ? 'seconize-dark-input-dropdown'
                : 'seconize-input-dropdown'
            "
          >
            <mat-option *ngFor="let bu of buList" [value]="bu.id">
              {{ bu.name }}
            </mat-option>
            <mat-option *ngIf="buList?.length <= 0">
              {{ isBUsLoading ? "Loading..." : "No Business Units Found" }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="seconize-dropdown-container gap-10">
      <div class="seconize-dropdown" *ngIf="isAdminBU && vendorRiskMgmt">
        <label for="">Business Unit</label>
        <mat-form-field
          appearance="outline"
          style="width: 100%; margin-top: 4px"
          class="input-dropdown"
          (click)="getAllBUs()"
        >
          <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
          <mat-select
            placeholder="Select BU"
            [formControl]="selectedBU"
            (selectionChange)="selectFilter('Business Unit', selectedBU.value)"
            [panelClass]="
              isDarkMode
                ? 'seconize-dark-input-dropdown'
                : 'seconize-input-dropdown'
            "
          >
            <mat-option *ngFor="let bu of buList" [value]="bu.id">
              {{ bu.name }}
            </mat-option>
            <mat-option *ngIf="buList?.length <= 0">
              {{ isBUsLoading ? "Loading..." : "No Business Units Found" }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
      <!-- <div class="seconize-dropdown-container gap-10">
                <div class="seconize-dropdown">
                    <label for="">Page</label>
                    <mat-form-field appearance="outline" style="width: 100%; margin-top: 4px;" class="input-dropdown">
                        <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                        <mat-select placeholder="Select Page" [formControl]="selectedSubCategory" (selectionChange)="
                    selectFilter('Permissions', selectedPrivilege.value)"
                            [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'">
                            <mat-optgroup *ngFor="let group of permissions" [label]="group.name">
                                <mat-option *ngFor="let subPage of group.children" [value]="subPage.name">
                                    {{ subPage.name=='Policy Bundles'?'Templates': subPage.name}}
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="seconize-dropdown">
                    <label>Privilege</label>
                    <mat-form-field appearance="outline" style="width: 100%; margin-top: 4px;" class="input-dropdown">
                        <i class="ph ph-caret-up-down audit-dropdown-arrow" matSuffix></i>
                        <mat-select placeholder="Select Privilege" [formControl]="selectedPrivilege" (selectionChange)="
                      selectFilter('Permissions', selectedPrivilege.value)
                    " [disabled]="!selectedSubCategory.value"
                            [matTooltip]="!selectedSubCategory.value?'Please Select Page':''"
                            [required]='selectedSubCategory.value'
                            [panelClass]="isDarkMode ? 'seconize-dark-input-dropdown' : 'seconize-input-dropdown'">
                            <mat-option *ngFor="let item of privilegesList" [value]="item.value">{{ item.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div> -->
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="audit-footer-btn">
    <button
      mat-fab
      extended
      class="cancel-btn btn-icon-txt btn"
      (click)="resetFilter('all', true)"
    >
      Reset
    </button>
    <button
      mat-fab
      extended
      class="primary-btn btn-icon-txt btn"
      (click)="onApply()"
    >
      Apply
    </button>
  </div>
</mat-dialog-actions>
