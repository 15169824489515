import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { GenericService } from '../../shared/genric-service/generic.service';
import { UsersService } from '../../services/users.service';
import { MatDialogRef } from '@angular/material/dialog';
import { pages } from '../../shared/models/nav-item';
import { jwtDecode, JwtPayload } from 'jwt-decode';

@Component({
  selector: 'app-on-board-users-filters',
  templateUrl: './on-board-users-filters.component.html',
  styleUrl: './on-board-users-filters.component.scss',
})
export class OnBoardUsersFiltersComponent implements OnInit {
  filteredUserNameOptions?: Observable<any[]>;
  filteredEmailIdOptions?: Observable<any[]>;
  userList: any = [];
  selectedStatus = new FormControl();
  selectedSSO = new FormControl();
  selectedMFA = new FormControl();
  selectedBU = new FormControl();
  selectedTeam = new FormControl();
  selectedVendor = new FormControl();
  selectedSubCategory = new FormControl();
  selectedUserId = new FormControl();
  selectedEmailId = new FormControl();
  selectedScope = new FormControl();
  selectedVulnScope = new FormControl();
  selectedAuditScope = new FormControl();
  selectedPolicyScope = new FormControl();
  selectedRiskScope = new FormControl();
  selectedPrivilege = new FormControl();
  selectedUserType = new FormControl();
  selectedUserRole = new FormControl();
  selectedTransferAssets = new FormControl('', [Validators.required]);
  selectedTransferVulnerabilites = new FormControl('', [Validators.required]);
  selectedTransferAssetGroups = new FormControl('', [Validators.required]);
  selectedTransferJobs = new FormControl('', [Validators.required]);
  selectedTransferReports = new FormControl('', [Validators.required]);
  selectedPermission = new FormControl();
  isUserIdsLoading: boolean = false;

  statusList: { name: string; value: string }[] = [];
  ssoList: any[] = [];
  mfaList: any[] = [];
  privilegesList: any[] = [];
  scopeList: any[] = [];
  vulnScopeList: any[] = [];
  auditScopeList: any[] = [];
  policyScopeList: any[] = [];
  riskScopeList: any[] = [];
  selectedFilter: any = [];
  permissions: any = [];
  buList: any = [];
  isAdminBU = false;
  searchMap: any = {};

  vulnMgmt: boolean = false;
  complianceMgmt: boolean = false;
  vendorRiskMgmt: boolean = false;
  asm: boolean = false;
  isDarkMode: boolean = false;

  userTypes = [
    { value: 'GRC', name: 'GRC' },
    { value: 'ADMIN', name: 'ADMIN' },
    { value: 'BOTH', name: 'BOTH' },
  ];

  vendorScopeList: { name: string; value: string }[];
  controlScopeList: { name: string; value: string }[];
  jobRoleList: { name: string; value: string }[];
  assetScopeList: { name: string; value: string }[];
  userScopeList: { name: string; value: string }[];
  connectorScopeList: { name: string; value: string }[];
  buScopeList: { name: string; value: string }[];
  remediationScopeList: { name: string; value: string }[];
  notificationScopeList: { name: string; value: string }[];
  sensorScopeList: { name: string; value: string }[];
  licenseScopeList: { name: string; value: string }[];
  complianceScopeList: { name: string; value: string }[];
  reportScopeList: { name: string; value: string }[];
  adminPermissions: any[] = [];
  grcPermissions: any[] = [];
  userModules: any = [];
  selectedUserModule: any = {};
  isBUsLoading: boolean = false;
  decodedToken: any;
  singleBu: string = 'false';

  booleanList: any = [
    { name: 'Yes', value: true },
    { name: 'No', value: false }
  ]

  constructor(
    private genericService: GenericService,
    private userService: UsersService,
    private matDialogRef: MatDialogRef<OnBoardUsersFiltersComponent>
  ) {
    this.decodedToken = jwtDecode<JwtPayload>(localStorage.getItem('token')!);
    if (localStorage.getItem('singleBu')?.toString()) {
      this.singleBu = localStorage.getItem('singleBu')?.toString()!;
    }

    if (this.decodedToken.isAdminDomain && this.singleBu == 'false') {
      this.isAdminBU = true;
    } else {
      this.isAdminBU = false;
    }
    this.statusList = this.userService.getStatus();
    this.ssoList = this.userService.getSSO();
    this.mfaList = this.userService.getMFA();
    // this.privilegesList = this.userService.getVulnPrivilegesList()
    this.vulnScopeList = userService.getVulnScopeList();
    this.scopeList = userService.getScopeList();
    this.auditScopeList = userService.getAuditScopeList();
    this.policyScopeList = userService.getPolicyScopeList();
    this.riskScopeList = userService.getRiskScopeList();
    this.userTypes = userService.getUserTypes();
    this.vulnScopeList = userService.getVulnScopeList();
    // this.scopeList = userService.getScopeList();
    this.auditScopeList = userService.getAuditScopeList();
    this.vendorScopeList = userService.getVendorScopeList();
    this.controlScopeList = userService.getControlScopeList();
    this.policyScopeList = userService.getPolicyScopeList();
    this.riskScopeList = userService.getRiskScopeList();
    this.jobRoleList = userService.getJobRoleList();
    // this.permissions = userService.getPermissionsList();

    // Import additional scope lists
    this.assetScopeList = userService.getAssetScopeList();
    this.userScopeList = userService.getUserScopeList();
    this.connectorScopeList = userService.getConnectorScopeList();
    this.buScopeList = userService.getBUScopeList();
    this.remediationScopeList = userService.getRemediationScopeList();
    this.notificationScopeList = userService.getNotificationScopeList();
    this.sensorScopeList = userService.getSensorScopeList();
    this.licenseScopeList = userService.getLicenseScopeList();
    this.complianceScopeList = userService.getComplianceScopeList();
    this.reportScopeList = userService.getReportScopeList();
    this.permissions = pages;
    localStorage.getItem('complianceMgmt') == 'true'
      ? (this.complianceMgmt = true)
      : (this.complianceMgmt = false);
    localStorage.getItem('vulnMgmt') == 'true'
      ? (this.vulnMgmt = true)
      : (this.vulnMgmt = false);
    localStorage.getItem('vendorRiskMgmt') == 'true'
      ? (this.vendorRiskMgmt = true)
      : (this.vendorRiskMgmt = false);
    localStorage.getItem('asm') == 'true'
      ? (this.asm = true)
      : (this.asm = false);
    this.genericService.isDarkMode.subscribe((res: boolean) => {
      this.isDarkMode = res;
    });
  }

  ngOnInit(): void {
    this.setSearchMap();
    if (this.isAdminBU) {
      this.getAllBUs();
    }
    if (this.searchMap['USER_TYPE']) {
      this.getPermission();
      if (this.searchMap['USER_ROLE']) {
        let currType;
        this.userModules.forEach((el: any) => {
          el.roles.forEach((elm: any) => {
            if (elm.value === this.selectedUserRole.value) {
              currType = el;
            }
          });
        });
        this.selectedUserModule = currType;
      }
    }
  }
  getPermission() {
    this.userModules = [];
    if (this.selectedUserType.value === 'ADMIN') {
      this.getAdminPermissions();
      this.userModules = this.adminPermissions;
    }
    if (this.selectedUserType.value === 'GRC') {
      this.getGrcPermissions();
      this.getAdminPermissions();
      this.userModules = [...this.grcPermissions, ...this.adminPermissions];
    }
    if (this.selectedUserType.value === 'BOTH' || this.selectedUserType.value === 'SUPER_ADMIN') {
      this.getGrcPermissions();
      this.getAdminPermissions();
      this.userModules = [...this.grcPermissions, ...this.adminPermissions];
    }
  }

  // Configure GRC Permissions
  getGrcPermissions() {
    let userType: string = this.selectedUserType.value;

    this.grcPermissions = [
      {
        module: 'Audit Management',
        moduleEnum: 'AUDIT',
        roles: [...this.auditScopeList],
        permissions: [...this.userService.getPermissionsList()],
        selectedRole:
          userType == 'ADMIN' || !this.complianceMgmt ? 'NONE' : 'AUDIT_MGR',
        selectedPermission:
          userType == 'ADMIN' || !this.complianceMgmt ? 'NONE' : 'MANAGE',
        disabled: userType == 'ADMIN' || !this.complianceMgmt,
      },
      {
        module: 'Policy Management',
        moduleEnum: 'POLICY',
        roles: [...this.policyScopeList],
        permissions: [...this.userService.getPermissionsList()],
        selectedRole:
          userType == 'ADMIN' || !this.complianceMgmt
            ? 'NONE'
            : 'POLICY_APPROVER',
        selectedPermission:
          userType == 'ADMIN' || !this.complianceMgmt ? 'NONE' : 'MANAGE',
        disabled: userType == 'ADMIN' || !this.complianceMgmt,
      },
      {
        module: 'Risk Management',
        moduleEnum: 'RISK',
        roles: [...this.riskScopeList],
        permissions: [...this.userService.getPermissionsList()],
        selectedRole:
          userType == 'ADMIN' || !this.complianceMgmt
            ? 'NONE'
            : 'RISK_APPROVER',
        selectedPermission:
          userType == 'ADMIN' || !this.complianceMgmt ? 'NONE' : 'MANAGE',
        disabled: userType == 'ADMIN' || !this.complianceMgmt,
      },
      {
        module: 'TPRM',
        moduleEnum: 'TPRM',
        roles: [...this.vendorScopeList],
        permissions: [...this.userService.getPermissionsList()],
        selectedRole:
          userType == 'ADMIN' || !this.vendorRiskMgmt ? 'NONE' : 'VENDOR_MGR',
        selectedPermission:
          userType == 'ADMIN' || !this.vendorRiskMgmt ? 'NONE' : 'MANAGE',
        disabled: userType == 'ADMIN' || !this.vendorRiskMgmt,
      },
      {
        module: 'Vuln Management',
        moduleEnum: 'VULN',
        roles: [...this.vulnScopeList],
        permissions: [...this.userService.getPermissionsList()],
        selectedRole:
          userType == 'ADMIN' || !this.vulnMgmt ? 'NONE' : 'VULN_MGR',
        selectedPermission:
          userType == 'ADMIN' || !this.vulnMgmt ? 'NONE' : 'MANAGE',
        disabled: userType == 'ADMIN' || !this.vulnMgmt,
      },
      {
        module: 'Control Management',
        moduleEnum: 'CONTROL',
        roles: [...this.controlScopeList],
        permissions: [
          { name: 'None', value: 'NONE' },
          { name: 'View', value: 'VIEW' },
        ],
        selectedRole:
          userType == 'ADMIN' || !this.complianceMgmt ? 'NONE' : 'CONTROL_MGR',
        selectedPermission:
          userType == 'ADMIN' || !this.complianceMgmt ? 'NONE' : 'VIEW',
        disabled: userType == 'ADMIN' || !this.complianceMgmt,
      },
    ];
  }
  getSelectedValue(module: any) {
    this.selectedUserModule = module;
  }

  // Configure Admin Permissions
  getAdminPermissions() {
    this.adminPermissions = [
      {
        module: 'Asset Management',
        moduleEnum: 'ASSET',
        roles: [...this.assetScopeList],
        permissions: [...this.userService.getPermissionsList()],
        selectedRole: 'ASSET_MGR',
        selectedPermission: 'MANAGE',
        disabled: false,
      },
      {
        module: 'User Management',
        moduleEnum: 'USER',
        roles: [...this.userScopeList],
        permissions: [...this.userService.getPermissionsList()],
        selectedRole: 'USER_MGR',
        selectedPermission: 'MANAGE',
        disabled: false,
      },
      {
        module: 'Connector Management',
        moduleEnum: 'CONNECTOR',
        roles: [...this.connectorScopeList],
        permissions: [...this.userService.getPermissionsList()],
        selectedRole: 'CONNECTOR_MGR',
        selectedPermission: 'MANAGE',
        disabled: false,
      },
      {
        module: 'Job Management',
        moduleEnum: 'JOB',
        roles: [...this.jobRoleList],
        permissions: [...this.userService.getPermissionsList()],
        selectedRole: 'JOB_MGR',
        selectedPermission: 'MANAGE',
        disabled: false,
      },
      {
        module: 'Business Unit Management',
        moduleEnum: 'BU',
        roles: [...this.buScopeList],
        permissions: [...this.userService.getPermissionsList()],
        selectedRole: 'BU_MGR',
        selectedPermission: 'MANAGE',
        disabled: false,
      },
      {
        module: 'Remediation Management',
        moduleEnum: 'REMEDIATION',
        roles: [...this.remediationScopeList],
        permissions: [...this.userService.getPermissionsList()],
        selectedRole: 'REMEDIATION_MGR',
        selectedPermission: 'MANAGE',
        disabled: false,
      },
      {
        module: 'Notification Management',
        moduleEnum: 'NOTIFICATION',
        roles: [...this.notificationScopeList],
        permissions: [{ name: 'Manage', value: 'MANAGE' }],
        selectedRole: 'NOTIFICATION_MGR',
        selectedPermission: 'MANAGE',
        disabled: false,
      },
      {
        module: 'Sensor Management',
        moduleEnum: 'SENSOR',
        roles: [...this.sensorScopeList],
        permissions: [...this.userService.getPermissionsList()],
        selectedRole: 'SENSOR_MGR',
        selectedPermission: 'MANAGE',
        disabled: false,
      },
      {
        module: 'License Management',
        moduleEnum: 'LICENSE',
        roles: [...this.licenseScopeList],
        permissions: [
          { name: 'None', value: 'NONE' },
          { name: 'View', value: 'VIEW' },
        ],
        selectedRole: 'LICENSE_MGR',
        selectedPermission: 'VIEW',
        disabled: false,
      },
      {
        module: 'Compliance Management',
        moduleEnum: 'COMPLIANCE',
        roles: [...this.complianceScopeList],
        permissions: [...this.userService.getPermissionsList()],
        selectedRole: 'COMPLIANCE_MGR',
        selectedPermission: 'MANAGE',
        disabled: false,
      },
      {
        module: 'Report Management',
        moduleEnum: 'REPORT',
        roles: [...this.reportScopeList],
        permissions: [
          { name: 'None', value: 'NONE' },
          { name: 'Manage', value: 'MANAGE' },
        ],
        selectedRole: 'REPORT_MGR',
        selectedPermission: 'MANAGE',
        disabled: false,
      },
    ];
  }
  getUsers() {
    if (this.userList.length <= 0) {
      this.isUserIdsLoading = true;
      var payload = {
        searchMap: {},
      };
      let api = '/identity/users/search/';
      this.genericService
        .postRequest(api, payload, false)
        .subscribe((data: any) => {
          this.isUserIdsLoading = false;
          if (
            data != null &&
            data.success &&
            data.data.users &&
            data.data.users.length > 0
          ) {
            data.data.users.forEach((el: any) => {
              this.userList.push({
                name: el.firstName + ' ' + el.lastName,
                userName: el.userName,
                emailId: el.emailId
              });
            });
            //below code is for auto completion of Asset name filter
            this.filteredUserNameOptions =
              this.selectedUserId.valueChanges.pipe(
                startWith(''),
                map((value) => this._filterUserName(value))
              );
            
            //below code is for auto completion of Asset name filter
            this.filteredEmailIdOptions =
              this.selectedEmailId.valueChanges.pipe(
                startWith(''),
                map((value) => this._filterEmailId(value))
              );
          }
        });
    }
  }

  private _filterUserName(value: string): string[] {
    const filterValue = value ? value.toLowerCase() : value;

    return this.userList?.filter((option: any) =>
      option?.userName
        ? option?.userName?.toLowerCase().includes(filterValue)
        : option?.userName
    );
  }

  private _filterEmailId(value: string): string[] {
    const filterValue = value ? value.toLowerCase() : value;

    return this.userList?.filter((option: any) =>
      option?.emailId
        ? option?.emailId?.toLowerCase().includes(filterValue)
        : option?.emailId
    );
  }

  selectFilter(filterType: string, filterValue: string) {
    if (filterType == 'Category->Sub Category') {
      this.selectedPrivilege.setValidators(Validators.required);
      this.selectedPrivilege.updateValueAndValidity();
      return;
    }
    var index = this.selectedFilter
      .map(function (x: any) {
        return x.name;
      })
      .indexOf(filterType);

    if (index !== -1) {
      this.selectedFilter.splice(index, 1);
    }

    if (filterType == 'Permissions') {
      this.privilegesList.forEach((type: any) => {
        if (type.value == this.selectedPrivilege.value) {
          // filterValue = this.selectedPrivilege.value;
          for (const group of this.permissions) {
            const subPage = group.children.find(
              (subPage: any) => subPage.name === this.selectedSubCategory.value
            );
            if (subPage) {
              filterValue =
                group.name +
                ' -> ' +
                (this.selectedSubCategory.value == 'Policy Bundles'
                  ? 'Templates'
                  : this.selectedSubCategory.value) +
                ' : ' +
                type.name;
            }
          }
        }
      });
    }

    if (filterType == 'Scope') {
      this.scopeList.forEach((type: any) => {
        if (type.value === this.selectedScope.value) {
          filterValue = type.name;
        }
      });
    }
    if (filterType == 'Vuln Role') {
      this.vulnScopeList.forEach((type: any) => {
        if (type.value === this.selectedVulnScope.value) {
          filterValue = type.name;
        }
      });
    }
    if (filterType == 'Audit Role') {
      this.auditScopeList.forEach((type: any) => {
        if (type.value === this.selectedAuditScope.value) {
          filterValue = type.name;
        }
      });
    }
    if (filterType == 'Policy Role') {
      this.policyScopeList.forEach((type: any) => {
        if (type.value === this.selectedPolicyScope.value) {
          filterValue = type.name;
        }
      });
    }
    if (filterType == 'Risk Role') {
      this.riskScopeList.forEach((type: any) => {
        if (type.value === this.selectedRiskScope.value) {
          filterValue = type.name;
        }
      });
    }
    if (filterType == 'Status') {
      this.statusList.forEach((type: any) => {
        if (type.value === this.selectedStatus.value) {
          filterValue = type.name;
        }
      });
    }
    if (filterType == 'SSO') {
      this.ssoList.forEach((type: any) => {
        if (type.value === this.selectedSSO.value) {
          filterValue = type.name;
        }
      });
    }
    if (filterType == 'MFA') {
      console.log('MFA :- ', this.selectedMFA.value, this.mfaList);
      this.mfaList.forEach((type: any) => {
        if (type.value?.toString() === this.selectedMFA.value?.toString()) {
          filterValue = type.name;
        }
      });

      console.log('filterValue :- ', filterValue);
    }
    if (filterType == 'Business Unit') {
      this.buList.forEach((bu: any) => {
        if (bu.id === this.selectedBU.value) {
          filterValue = bu.name;
        }
      });
    }
    if (filterType == 'Vendor User') {
      this.booleanList.forEach((boolean: any) => {
        if (boolean.value === this.selectedVendor.value) {
          filterValue = boolean.name;
        }
      });
    }
    if (filterType == 'Team') {
      this.teamList.forEach((team: any) => {
        if (team.id === this.selectedTeam.value) {
          filterValue = team.name;
        }
      });
    }
    if (filterValue !== null) {
      this.selectedFilter.push({
        name: filterType,
        value: filterValue,
      });
    }

    this.updateSearchMap();
  }

  onClose() {
    this.matDialogRef.close({ type: 'APPLY' });
  }

  getAllBUs() {
    if (this.buList.length === 0) {
      this.isBUsLoading = true;

      let filter: any = {
        startIndex: '',
        count: '',
        sortBy: '',
        sortOrder: '',
        searchMap: {},
        excludeMap: {},
      };

      let api = '/bu/search/';
      this.genericService
        .postRequest(api, filter, false)
        .subscribe((data: any) => {
          this.isBUsLoading = false;
          if (
            data.success &&
            data.data != null &&
            data.data.businessUnits.length > 0
          ) {
            this.buList = data.data.businessUnits;
            this.buList.forEach((bu: any) => {
              if (
                bu.name == this.decodedToken?.buName &&
                this.singleBu == 'false'
              ) {
                if (
                  this.searchMap['BU_ID'] &&
                  this.searchMap['BU_ID'] === bu.id
                ) {
                  this.selectedBU.patchValue(bu.id);
                }
                // this.searchMap['BU_ID'] = bu.id
              }
            });
          }
        });
    }
  }

  // RESET FILTERS
  resetFilter(filterType: string, callGetAllFn?: boolean) {
    //clean filter type from selectedFilter array as well
    var index = this.selectedFilter
      .map(function (x: any) {
        return x.name;
      })
      .indexOf(filterType);
    if (index !== -1) this.selectedFilter.splice(index, 1);

    if (filterType == 'User Id') {
      this.selectedUserId.patchValue(null);
      delete this.searchMap['USER_NAME'];
    }
    if (filterType == 'Email Id') {
      this.selectedEmailId.patchValue(null);
      delete this.searchMap['EMAIL_ID'];
    }
    if (filterType == 'Status') {
      this.selectedStatus.patchValue(null);
      delete this.searchMap['STATUS'];
    }
    if (filterType == 'SSO') {
      this.selectedSSO.patchValue(null);
      delete this.searchMap['IS_SSO_USER'];
    }
    if (filterType == 'MFA') {
      this.selectedMFA.patchValue(null);
      delete this.searchMap['MFA_ENABLED'];
    }
    if (filterType == 'Business Unit') {
      this.selectedBU.patchValue(null);
      delete this.searchMap['BU_ID'];
    }
    if (filterType == 'Vendor User') {
      this.selectedVendor.patchValue(null);
      delete this.searchMap['IS_VENDOR'];
    }
    if (filterType == 'Team') {
      this.selectedTeam.patchValue(null);
      delete this.searchMap['TEAM'];
    }
    if (filterType == 'Scope') {
      this.selectedScope.patchValue(null);
      delete this.searchMap['SCOPE'];
    }
    if (filterType == 'Vuln Role') {
      this.selectedVulnScope.patchValue(null);
      delete this.searchMap['VULN_ROLE'];
    }
    if (filterType == 'Audit Role') {
      this.selectedAuditScope.patchValue(null);
      delete this.searchMap['AUDIT_ROLE'];
    }
    if (filterType == 'Policy Role') {
      this.selectedPolicyScope.patchValue(null);
      delete this.searchMap['POLICY_ROLE'];
    }
    if (filterType == 'Risk Role') {
      this.selectedRiskScope.patchValue(null);
      delete this.searchMap['RISK_ROLE'];
    }
    if (filterType == 'Permissions') {
      this.selectedPrivilege.patchValue(null);
      this.selectedSubCategory.patchValue(null);
      delete this.searchMap['MAIN_MODULE'];
      delete this.searchMap['SUB_MODULE'];
      delete this.searchMap['PERMISSION'];
    }

    if (filterType == 'all') {
      this.selectedFilter.length = 0;
      this.selectedUserId.patchValue('');
      this.selectedEmailId.patchValue('');
      this.selectedStatus.patchValue(null);
      this.selectedSSO.patchValue(null);
      this.selectedMFA.patchValue(null);
      this.selectedBU.patchValue(null);
      this.selectedScope.patchValue(null);
      this.selectedVulnScope.patchValue(null);
      this.selectedAuditScope.patchValue(null);
      this.selectedPolicyScope.patchValue(null);
      this.selectedRiskScope.patchValue(null);
      this.selectedSubCategory.patchValue(null);
      this.selectedPrivilege.patchValue(null);
      this.selectedUserType.patchValue(null);
      this.selectedUserRole.patchValue(null);
      this.selectedPermission.patchValue(null);
      this.selectedTeam.patchValue(null);
      this.selectedVendor.patchValue(null);
      this.searchMap = {};
    }
  }

  onApply() {
    this.updateSearchMap();
    this.matDialogRef.close({ data: this.searchMap, type: 'APPLY' });
  }

  updateSearchMap() {
    this.searchMap = {};
    if (this.selectedSubCategory.value && this.selectedPrivilege.value) {
      if (
        this.selectedSubCategory.value === 'Admin->Assets' ||
        this.selectedSubCategory.value === 'Policy Bundles' ||
        this.selectedSubCategory.value === 'Audit Rules' ||
        this.selectedSubCategory.value === 'Compliances' ||
        this.selectedSubCategory.value === 'Licenses' ||
        this.selectedSubCategory.value === 'Sensors' ||
        this.selectedSubCategory.value === 'Notifications' ||
        this.selectedSubCategory.value === 'Remediation' ||
        this.selectedSubCategory.value === 'Reports' ||
        this.selectedSubCategory.value === 'Jobs' ||
        this.selectedSubCategory.value === 'Users' ||
        this.selectedSubCategory.value === 'Connectors'
      ) {
        if (this.selectedSubCategory.value === 'Admin->Assets') {
          this.searchMap['MAIN_MODULE'] = 'Administration';
          this.searchMap['SUB_MODULE'] =
            this.selectedSubCategory.value.split('->')[1];
          this.searchMap['PERMISSION'] = this.selectedPrivilege.value;
        } else {
          this.searchMap['MAIN_MODULE'] = 'Administration';
          this.searchMap['SUB_MODULE'] = this.selectedSubCategory.value;
          this.searchMap['PERMISSION'] = this.selectedPrivilege.value;
        }
      } else if (
        this.selectedSubCategory.value === 'Assets' ||
        this.selectedSubCategory.value === 'Services' ||
        this.selectedSubCategory.value === 'Remediations' ||
        this.selectedSubCategory.value === 'Vulnerabilities' ||
        this.selectedSubCategory.value === 'Risk Profile' ||
        this.selectedSubCategory.value === 'Findings'
      ) {
        this.searchMap['MAIN_MODULE'] = 'Vuln Mgmt';
        this.searchMap['SUB_MODULE'] = this.selectedSubCategory.value;
        this.searchMap['PERMISSION'] = this.selectedPrivilege.value;
      } else if (this.selectedSubCategory.value === 'Dashboard') {
        this.searchMap['MAIN_MODULE'] = 'ASM';
        this.searchMap['SUB_MODULE'] = this.selectedSubCategory.value;
        this.searchMap['PERMISSION'] = this.selectedPrivilege.value;
      } else {
        this.searchMap['MAIN_MODULE'] = 'Compliance Mgmt';
        this.searchMap['SUB_MODULE'] = this.selectedSubCategory.value;
        this.searchMap['PERMISSION'] = this.selectedPrivilege.value;
      }
    }
    if (this.selectedUserId.value) {
      this.searchMap['USER_NAME'] = this.selectedUserId.value;
    }
    if (this.selectedEmailId.value) {
      this.searchMap['EMAIL_ID'] = this.selectedEmailId.value;
    }
    if (this.selectedStatus.value) {
      this.searchMap['STATUS'] = this.selectedStatus.value;
    }
    if (this.selectedSSO.value) {
      this.searchMap['IS_SSO_USER'] = this.selectedSSO.value;
    }
    if (this.selectedMFA.value?.toString().length) {
      this.searchMap['MFA_ENABLED'] = this.selectedMFA.value;
    }
    if (this.selectedBU.value && this.singleBu == 'false') {
      this.searchMap['BU_ID'] = this.selectedBU.value;
    }
    if (this.selectedTeam.value) {
      this.searchMap['TEAM'] = this.selectedTeam.value
    }
    if (this.selectedVendor.value) {
      this.searchMap['IS_VENDOR'] = this.selectedVendor.value;
    }
    if (this.selectedScope.value) {
      this.searchMap['SCOPE'] = this.selectedScope.value;
    }
    if (this.selectedVulnScope.value) {
      this.searchMap['VULN_ROLE'] = this.selectedVulnScope.value;
    }
    if (this.selectedAuditScope.value) {
      this.searchMap['AUDIT_ROLE'] = this.selectedAuditScope.value;
    }
    if (this.selectedPolicyScope.value) {
      this.searchMap['POLICY_ROLE'] = this.selectedPolicyScope.value;
    }
    if (this.selectedRiskScope.value) {
      this.searchMap['RISK_ROLE'] = this.selectedRiskScope.value;
    }
    if (this.selectedUserType.value) {
      this.searchMap['USER_TYPE'] = this.selectedUserType.value;
    }
    if (this.selectedPermission.value) {
      this.searchMap['USER_PERMISSION'] = this.selectedPermission.value;
    }
    if (this.selectedUserRole.value) {
      this.searchMap['USER_ROLE'] = this.selectedUserRole.value;
    }
    if (this.selectedPermission.value && this.selectedUserRole.value) {
      this.searchMap['USER_MODULE'] = this.selectedUserModule.moduleEnum;
    }
  }

  setSearchMap() {
    if (this.searchMap['USER_NAME']) {
      this.selectedUserId.setValue(this.searchMap['USER_NAME']);
    }
    if (this.searchMap['EMAIL_ID']) {
      this.selectedEmailId.setValue(this.searchMap['EMAIL_ID']);
    }
    if (this.searchMap['STATUS']) {
      this.selectedStatus.setValue(this.searchMap['STATUS']);
    }
    if (this.searchMap['IS_SSO_USER']) {
      this.selectedSSO.setValue(this.searchMap['IS_SSO_USER']);
    }
    if (this.searchMap['MFA_ENABLED']) {
      this.selectedMFA.setValue(this.searchMap['MFA_ENABLED']);
    }
    if (this.searchMap['BU_ID'] && this.singleBu == 'false') {
      this.selectedBU.setValue(this.searchMap['BU_ID']);
    }
    if (this.searchMap['TEAM']) {
      this.selectedTeam.setValue(this.searchMap['TEAM']);
    }
    if (this.searchMap['IS_VENDOR']) {
      this.selectedVendor.setValue(this.searchMap['IS_VENDOR']);
    }
    if (this.searchMap['SCOPE']) {
      this.selectedScope.setValue(this.searchMap['SCOPE']);
    }
    if (this.searchMap['VULN_ROLE']) {
      this.selectedVulnScope.setValue(this.searchMap['VULN_ROLE']);
    }
    if (this.searchMap['AUDIT_ROLE']) {
      this.selectedAuditScope.setValue(this.searchMap['AUDIT_ROLE']);
    }
    if (this.searchMap['POLICY_ROLE']) {
      this.selectedPolicyScope.setValue(this.searchMap['POLICY_ROLE']);
    }
    if (this.searchMap['RISK_ROLE']) {
      this.selectedRiskScope.setValue(this.searchMap['RISK_ROLE']);
    }
    if (this.searchMap['USER_TYPE']) {
      this.selectedUserType.setValue(this.searchMap['USER_TYPE']);
    }
    if (this.searchMap['USER_PERMISSION']) {
      this.selectedPermission.setValue(this.searchMap['USER_PERMISSION']);
    }
    if (this.searchMap['USER_ROLE']) {
      this.selectedUserRole.setValue(this.searchMap['USER_ROLE']);
    }
    // if (this.searchMap['USER_MODULE']) {
    //   this.selectedUserRole.setValue(this.searchMap['USER_MODULE']);
    // }
  }

  teamList: any = [];
  isTeamLoading: boolean = false;
  getAllTeams() {
    this.isTeamLoading = true;
    let teamFilter: any = {
      startIndex: 1,
      count: "15",
      groupBy: "",
      excludeMap: {},
      searchMap: {}
    };
    let api = '/identity/teams/search/';
    this.genericService.postRequest(api, teamFilter).subscribe((data: any) => {
      this.isTeamLoading = false;
      if (data.success && data.data != null) {
        this.teamList = data.data
      }
    });
  }
}
